const LEGEND_WIDTH = 320;
const LEGEND_HEIGHT = 20;
const LEGEND_OFFSETX = 50;
const LEGEND_OFFSETY = 620;
const LEGEND_TICKS = LEGEND_WIDTH / 64;

export {
  LEGEND_WIDTH,
  LEGEND_HEIGHT,
  LEGEND_OFFSETX,
  LEGEND_OFFSETY,
  LEGEND_TICKS,
};
